/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactVisibilitySensor from 'react-visibility-sensor';
import { changeColorOnVisible } from 'hooks/changeColorOnScroll';
import { Link } from 'react-router-dom';
import MovingPicture from 'components/MovingPicture';
import { useDispatch } from 'react-redux';

const DigitalHomeSection = () => {
  const { t } = useTranslation();
  const [isSlideVisible, setIsSlideVisible] = useState(false);
  const [isSlideSeen, setIsSlideSeen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    changeColorOnVisible('#f9ffe0', isSlideVisible, dispatch);
    if (!isSlideSeen && isSlideVisible) setIsSlideSeen(true);
    // eslint-disable-next-line
  }, [isSlideVisible]);

  const title = t('service.digital.title');

  return (
    <>
      <div id="digital" className="d-flex  position-relative container justify-content-center flex-column min-vh-100">
        <ReactVisibilitySensor onChange={setIsSlideVisible} partialVisibility>
          <div className="d-flex d-md-none  justify-content-center">
            <span className="display-1 link--nukun position-relative">
              <span>{title[0]}</span>
              {title.substring(1)}
            </span>
          </div>
        </ReactVisibilitySensor>
        <div className="row">
          <MovingPicture
            maxHeight="130%"
            className="position-relative my-5 d-flex align-items-center justify-content-center col-12 col-lg-6"
            layer1="assets/images/digital/global-layer-1.webp"
            layer2="assets/images/digital/global-layer-2.webp"
            layer3="assets/images/digital/global-layer-3.webp"
          />
          <div className="col-12 col-lg-6">
            <ReactVisibilitySensor onChange={setIsSlideVisible} partialVisibility>
              <div className="d-none d-md-flex mb-11 ">
                <span className="display-1 link--nukun position-relative">
                  <span>{title[0]}</span>
                  {title.substring(1)}
                </span>
              </div>
            </ReactVisibilitySensor>
            <div className="letter-effect text-reveal-smaller text-dark font-weight-normal text-reveal ">
              {t('service.digital.1')}
            </div>
            <div className="letter-effect text-reveal-smaller text-dark font-weight-normal mb-7 text-reveal">
              {t('service.digital.2')}
            </div>
          </div>
        </div>

        <Link to="/digital" className="align-self-end continue-reading-left text-dark">
          {t('seeMore')}
        </Link>
      </div>
    </>
  );
};

export default DigitalHomeSection;
